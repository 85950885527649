
.check-wrap[data-v-1e20a507]{margin-top:20px;margin-bottom:20px;margin-left:43%;width:70px;height:70px;border-radius:50%;border:2px solid #00afcf;position:relative;overflow:hidden;-webkit-animation:wrap-data-v-1e20a507 .3s ease-in-out forwards;animation:wrap-data-v-1e20a507 .3s ease-in-out forwards;-webkit-animation-delay:.3s;animation-delay:.3s;-webkit-transform:scale(0);transform:scale(0)
}
.check-wrap[data-v-1e20a507]:after,.check-wrap[data-v-1e20a507]:before{content:"";position:absolute;background-color:#fff;width:0;height:5px;-webkit-transform-origin:left;transform-origin:left;-webkit-animation-duration:.3s;animation-duration:.3s;-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out;-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards
}
.check-wrap[data-v-1e20a507]:before{top:32px;left:21px;-webkit-transform:rotate(45deg);transform:rotate(45deg);-webkit-animation-name:left-data-v-1e20a507;animation-name:left-data-v-1e20a507;-webkit-animation-delay:.8s;animation-delay:.8s
}
.check-wrap[data-v-1e20a507]:after{top:42px;left:29px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);-webkit-animation-name:right-data-v-1e20a507;animation-name:right-data-v-1e20a507;-webkit-animation-delay:1.1s;animation-delay:1.1s
}
@-webkit-keyframes wrap-data-v-1e20a507{
0%{background-color:transparent;-webkit-transform:scale(0);transform:scale(0)
}
to{background-color:#00afcf;-webkit-transform:scale(1);transform:scale(1)
}
}
@keyframes wrap-data-v-1e20a507{
0%{background-color:transparent;-webkit-transform:scale(0);transform:scale(0)
}
to{background-color:#00afcf;-webkit-transform:scale(1);transform:scale(1)
}
}
@-webkit-keyframes left-data-v-1e20a507{
0%{width:0
}
to{width:15px
}
}
@keyframes left-data-v-1e20a507{
0%{width:0
}
to{width:15px
}
}
@-webkit-keyframes right-data-v-1e20a507{
0%{width:0
}
to{width:30px
}
}
@keyframes right-data-v-1e20a507{
0%{width:0
}
to{width:30px
}
}